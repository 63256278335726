<template lang="pug">
.main-wrapper.grupos-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "caixas" }'> Caixas</router-link> /
                        <b>{{  'Gerenciar forma(s) de pagamento' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`Forma(s) de pagamento`")
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto;')
                        //- componentes de busca
                        .p-col-6
                            label.form-label Nome Caixa: 
                            .p-inputgroup.my-2
                                InputText(v-model='caixasFilter')
                                Button(v-if='caixasFilter.length' icon='jam jam-rubber' type='button' @click="caixasFilter = ''")
                        .p-col-6
                            label.form-label Forma(s) de pagamento: 
                            .my-2
                                CustomMultiSelect(v-model='caixasFilterFormasPagamento' :options='options.formasPagamento'
                                    dataKey='id' optionLabel='name' optionValue='id' display='chip' placeholder='Selecione' )
                        
                        //- componentes de selecao formas pagamento
                        .p-col-12
                            .endpoints-container
                                .p-grid.p-fluid.p-align-center.endpoint-row(v-for='val, key, idx in filteredCaixas()' :key='key' :class="{ odd: idx % 2 != 0 }")
                                    .p-col-12.p-md-2
                                        p.text-url {{ val.nm_caixa }}
                                    .p-col-12.p-md-10
                                        .p-grid.p-fluid.p-align-center
                                            .p-col(v-for='forma_pagamento in options.formasPagamento')
                                                InputSwitch(:value='options.caixas[key].cd_formas_pagamento.includes(forma_pagamento.id)' @change="onInput(key, forma_pagamento.id)")
                                                span.text-method(style='font-size:12px') {{ forma_pagamento.name }}

                        .p-col-12.mt-2.ta-center
                            Button(label='Salvar' type="submit" style='max-width:200px;')     
</template>

<style lang="scss">
    .grupos-salvar {
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .qtd-text {
            color: #657786;
            font-size: 12px;
            float: right;
        }
        .endpoints-container, .menus-container {
            max-height: 400px;
            overflow-y: scroll;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
        }
        .menus-container {
            max-height: 452px;
            .p-field-checkbox {
                padding: 10px;
                margin: 0;
                &.child { padding-left: 40px; }
            }
        }
        .endpoint-row {
            margin: 0;
            padding: 0 10px;
            &.odd {
                background-color: #eee;
            }
            .text-url {
                margin: 0;
                font-size: 13px;
                font-weight: 700;
            }
            .text-method {
                display: inline-block;
                vertical-align: middle;
                margin-left: 6px;
                font-size: 13px;
                font-weight: 700;
            }
            .p-inputswitch {
                vertical-align: middle;
            }
        }
        .p-slider {
            margin: 15px 0;
        }
        .p-slider-handle {
            cursor: pointer !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from 'primevue/inputswitch'
    import Slider from 'primevue/slider'
    import Checkbox from 'primevue/checkbox'
    import CustomMultiSelect from '../CustomComponents/CustomMultiSelect.vue'

    import { Caixas } from './../../middleware'
    import _ from 'lodash'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Slider, Checkbox,
                InputMask, SelectButton, Dropdown, Tooltip, InputSwitch, CustomMultiSelect
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            Caixas.getFormasPagamento().then(response => {
                if (response.status === 200) {
                    response.data.forEach((un) => {
                        this.options.formasPagamento.push({ id: un.id, name: un.nm_forma_pagamento })
                    });

                    Caixas.findAll().then(response => {
                        if (response.status == 200) {
                            response.data.forEach(caixa => {
                                this.options.caixas[caixa.id] = {
                                    'cd_caixa': caixa.id,
                                    'nm_caixa': caixa.nm_caixa,
                                    'cd_formas_pagamento': caixa.cd_formas_pagamento
                                }
                            })
                        }
                        this.waiting = false
                        return true
                    })
                }
            })
        },
        data () {
            return {
                model: {
                    caixas: [],
                },
                options: {
                    formasPagamento: [],
                    selectedFormasPagamento: [],
                    caixas: {},
                    copy_caixas: {}
                },
                waiting: true,
                waitingForm: false,
                submitted: false,
                caixasFilter: '',
                caixasFilterFormasPagamento: [],
            }
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                this.model.caixas = []

                for (let cd_caixa in this.options.copy_caixas) {
                    if (!_.isEqual(this.options.copy_caixas[cd_caixa], this.options.caixas[cd_caixa])) {
                        this.model.caixas.push({
                            'cd_caixa': cd_caixa,
                            'cd_formas_pagamento': this.options.caixas[cd_caixa].cd_formas_pagamento
                        })
                    }
                }

                let dataSend = Object.assign({}, this.model)

                if (!dataSend.caixas.length) {
                    this.$toast.warning('Nenhuma forma de pagamento foi modificada', { duration: 3000 })
                    return false
                }
                
                this.waitingForm = true
                Caixas.saveCaixaFormasPagamento(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Formas de pagamento alteradas com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        } else if(response.data.detail) {
                            response.data.detail.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        }
                    }
                    this.waitingForm = false
                })
            },
            onInput (cd_caixa, cd_forma_pagamento) {
                if (!this.options.copy_caixas[cd_caixa]) {
                    // salvando valor original de caixa alterado para comparacao no fim do fluxo
                    this.options.copy_caixas[cd_caixa] = JSON.parse(JSON.stringify(this.options.caixas[cd_caixa]))
                }

                if (this.options.caixas[cd_caixa].cd_formas_pagamento.includes(cd_forma_pagamento)) {
                    this.options.caixas[cd_caixa].cd_formas_pagamento = this.options.caixas[cd_caixa].cd_formas_pagamento.filter(forma => forma != cd_forma_pagamento)
                }else {
                    this.options.caixas[cd_caixa].cd_formas_pagamento.push(cd_forma_pagamento)
                }

                this.$forceUpdate()
            },
            normalizeString(str) {
                str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");  // removendo acentuacao grafica
                str = str.replace(/\s+/g, ' ');  // removendo espacos em branco desnecessarios
                str = str.toLowerCase();  // normalizando como minusculas
                
                return str
            },
            filteredCaixas () {
                if (!this.caixasFilter.length && !this.caixasFilterFormasPagamento.length) return this.options.caixas
                
                let result = {}
                let query = Object.values(this.options.caixas)
                
                // filtrar caixas por nome do caixa
                if (this.caixasFilter.length) {
                    let str_fitlro = this.normalizeString(this.caixasFilter)

                    query = Object.values(this.options.caixas).filter(caixa => {
                        let nm_caixa = this.normalizeString(caixa.nm_caixa)  // normalizando tambem nome do caixa

                        return nm_caixa.includes(str_fitlro)
                    })
                }

                // filtrar caixas por forma de pagamento
                if (this.caixasFilterFormasPagamento.length) {  
                    query = query.filter(caixa => {
                        return caixa.cd_formas_pagamento.some(forma => this.caixasFilterFormasPagamento.includes(forma));
                    });
                }

                query.forEach(caixa => result[caixa.cd_caixa] = caixa);

                return result
            }
        }
    }
</script>